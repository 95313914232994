import request from '../utils/request.js'

export function getUserInfo () {
  return request({
    url: 'api/user/index',
    method: 'post'
  })
}

export function setUserAttestation (data) {
  return request({
    url: 'api/user/setAttestation',
    method: 'post',
    data
  })
}

export function getBiddingList (data) {
  return request({
    url: 'api/user/getBiddingList',
    method: 'post',
    data
  })
}

export function getBiddingLog(data) {
  return request({
    url: '/api/user/getBiddingLog',
    method: 'post',
    data
  })
}

export function resetPwd(data) {
  return request({
    url: '/api/user/resetpwd',
    method: 'post',
    data
  })
}

export function loginOut () {
  return request({
    url: '/api/user/logout',
    method: 'post'
  })
}

export function unbindWx() {
  return request({
    url: '/api/user/unbindWx',
    method: 'post'
  })
}
