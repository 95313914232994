import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'

// 1. 创建axios对象
const service = axios.create({
  baseURL: 'https://admin.dypmw.com/', // 所有的请求地址前缀部分
  timeout: 60000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
  headers: {
    // 设置后端需要的传参类型
    'Content-Type': 'application/json;charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': '*',
  }
})
// 添加请求拦截器
service.interceptors.request.use(config => {
  // 在发送请求之前做些什么

  const token = localStorage.getItem('token')

  if (token) {
    config.headers.token = token
  }

  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  return response
}, function (error) {
  if (error.response.data.code === 401) {
    ElMessage('登录过期，请重新登录')
    localStorage.setItem('token', '')
    localStorage.setItem('nickname', '')
    console.log(router)

    setTimeout(function () {
      console.log(router)
      router.push('/index')
    }, 500)
    return error.response.data
  }

  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error)
})
export default service
