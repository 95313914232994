import request from '../utils/request.js'

export function index(data) {
  return request({
    url: '/api/index/index',
    method: 'post',
    data: data
  })
}

export function getDate(data) {
  return request({
    url: 'api/index/getDate',
    method: 'post',
    data
  })
}

export function getDateToData(data) {
  return request({
    url: 'api/index/getDateToData',
    method: 'post',
    data
  })
}

export function getOtherInfo(data) {
  return request({
    url: 'api/index/getOther',
    method: 'post',
    data
  })
}

export function getBiddingCate() {
  return request({
    url: 'api/index/getBiddingCate',
    method: 'post'
  })
}

export function registerToWx(data) {
  return request({
    url: 'api/user/registerToWx',
    method: 'post',
    data
  })
}

export function checkToken(data) {
  return request({
    url: 'api/token/check',
    method: 'post',
    data
  })
}
