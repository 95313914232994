import {
  createApp
} from 'vue'
import router from './router'
import store from './store'
import Element from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue' // 如果您正在使用CDN引入，请删除
import App from './App.vue'

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

app.use(Element).use(store).use(router)

app.mount('#app')
