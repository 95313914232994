<template>
  <Header></Header>
  <div class="banner">
    <el-carousel height="40rem" :interval="2000">
      <el-carousel-item v-for="item in banner" :key="item">
        <img :src="item.image" alt="">
      </el-carousel-item>
    </el-carousel>
    <div class="banner_item"></div>
  </div>
  <div class="show">
    <div class="show_item">
      <div class="show_item_img"><img src="../assets/price.png" alt=""></div>
      <div style="margin-left: 0.5rem">成交总金额</div>
      <div style="margin-left: 0.5rem">￥{{priceNumber}}万元</div>
    </div>
    <div class="show_item" style="margin-left: 21rem">
      <div class="show_item_img"><img src="../assets/number.png" alt=""></div>
      <div style="margin-left: 0.5rem">竞拍会员人数</div>
      <div style="margin-left: 0.5rem">{{userNumber}}人</div>
    </div>
  </div>
  <div class="middle_img"><img src="../assets/liucheng.jpg" alt=""></div>
  <div class="imgBtn">
    <div class="imgBtn_item_one cursor" @click="goPath('/bidding', 2)"><img src="../assets/jingpailist.png" alt="">
    </div>
    <div class="imgBtn_item cursor" @click="goPath('/biddingTrue', 3)"><img src="../assets/anli.png" alt=""></div>
    <div class="imgBtn_item cursor" @click="goPath('/noticeList', 1)"><img src="../assets/gonggao.png" alt=""></div>
  </div>
  <div class="recommend"><img src="../assets/recommend.png" alt=""></div>
  <div class="startList">
    <div class="startList_item" v-for="item in bidding" :key="item.id">
      <div class="start_img">
        <img src="../assets/tuijian.png" alt="">
      </div>
      <div class="li_img cursor" @click="goPath('biddingInfo?id='+item.id, 2)"><img :src="item.images" alt=""></div>
      <div class="li_content cursor" @click="goPath('biddingInfo?id='+item.id, 2)">
        <div class="lic_title">{{ item.title }}</div>
        <div class="lic_test lic_title_sub">拍卖机构：{{item.enterprise_title}}</div>
        <div class="lic_test">起拍价：<span style="font-size: 18px;color:red">￥{{ item.rise_price }}</span></div>
        <div class="lic_test">标的数量 {{ item.number }} / {{ item.unit }}</div>
        <div class="lic_test">开拍时间：{{ item.start_time_text }}</div>
        <div class="lic_test recommend_btn_start" v-if="item.status == 0">即将开始</div>
        <div class="lic_test recommend_btn_ture" v-if="item.status == 1">正在进行</div>
        <div class="lic_test recommend_btn_false" v-if="item.status > 1">竞拍结束</div>
      </div>
    </div>
    <div class="cursor" @click="goPath('/bidding',2)" style="margin:1rem auto;width: 5rem">查看更多 ></div>
  </div>
  <div class="recommend"><img src="../assets/auction.png" alt=""></div>
  <div class="time_show">
    <div class="j_left cursor" @click="downCli"><img src="../assets/left.png" alt=""></div>
    <div class="time_test cursor" v-for="(item,index) in bidding_two_date" :key="item.id"
      :style="cli === index? 'color: red': ''" @click="cliColor(index, item.date)">
      <div>{{ item.date }}</div>
      <div>{{ item.count }}场</div>
    </div>
    <div class="j_right cursor" @click="upCli"><img src="../assets/right.png" alt=""></div>
  </div>
  <div class="s_list">
    <div class="s_list_item cursor" v-if="bidding_two.length > 0" v-for="item in bidding_two" :key="item.id"
      @click="goPath('biddingInfo?id='+item.id, 2)">
      <div class="sli_img"><img :src="item.images" alt=""></div>
      <div class="sli_content">
        <div class="slic_test slic_title" style="font-size: 18px;font-weight: bold;">{{ item.title }}</div>
        <div class="slic_test slic_title">拍卖机构：{{ item.enterprise_title }}</div>
        <div class="slic_test">起拍价：<span style="font-size: 18px;color:red">￥{{ item.rise_price }}</span></div>
        <div class="slic_test">标的数量：{{ item.number }} / {{ item.unit }}</div>
        <div class="slic_test">开拍时间：{{ item.start_time_text }}</div>
        <div class="slic_btn_start" v-if="item.status == 0">即将开始</div>
        <div class="slic_btn_true" v-if="item.status == 1">正在进行</div>
        <div class="slic_btn_false" v-if="item.status > 1">竞拍结束</div>
      </div>
    </div>
    <div class="s_list_false" v-else>暂无拍卖会</div>

    <div class="cursor" @click="goPath('/bidding',2)" style="margin:1rem 26.5rem">查看更多 ></div>
  </div>
  <div class="recommend"><img src="../assets/cooperativeenterprise.png" alt=""></div>
  <div class="cooperation_enterprise">
    <!--    <div class="ce_item" v-for="item in enterprise_h" :key="item.id"><img :src="item.image" alt=""></div>-->
    <el-carousel height="15rem" :interval="2000" :autoplay="false" indicator-position="none">
      <el-carousel-item class="el-car-item" v-for="item in enterprise_h" :key="item.id">
        <img class="ce_item" v-for="val in item" :src="val.image" alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
  <div class="recommend_two"><img src="../assets/Settleinenterprises.png" alt=""></div>
  <div class="settle_enterprise">
    <div class="se_item" v-for="item in enterprise_r" :key="item.id">
      <div class="sei_img"><img :src="item.image" alt=""></div>
      <div class="sei_name">{{ item.name }}</div>
    </div>
    <div class="cursor" @click="goPath('/enterprise', -1)" style="margin:1rem auto;width: 5rem">查看更多 ></div>
  </div>
  <div class="recommend"><img src="../assets/Hotconsultation.png" alt=""></div>
  <div class="test">
    <div class="test_item cursor" v-for="item in news" :key="item.id" @click="goPath('/newsInfo?id='+item.id)">
      <div class="test_item_left">
        <!--        <div class="til_img"><img :src="item.image" alt=""></div>-->
        <div class="til_test">{{ item.title }}</div>
      </div>
      <div class="test_item_left">{{ item.create_time_text }}</div>
    </div>
    <div class="cursor" @click="goPath('/newsList', 4)" style="margin:1rem auto;width: 5rem">查看更多 ></div>
  </div>
  <el-backtop :right="100" :bottom="100" style="background: gainsboro" />
  <Footer></Footer>
</template>

<script>
  import Header from './Header'
  import Footer from './Footer'
  import {
    index,
    getDate,
    getDateToData,
    registerToWx
  } from '@/api/index.js'

  export default {
    name: 'Index',
    components: {
      Header,
      Footer
    },
    data() {
      return {
        cli: -1,
        date: 1,
        news: [],
        banner: [],
        bidding: [],
        bidding_two: [],
        bidding_two_date: [],
        enterprise_r: [],
        enterprise_h: [],
        userNumber: 0,
        priceNumber: 0
      }
    },
    created() {
      this.index()
      this.getDate()
      localStorage.setItem('cli', 0)

      if (this.$route.query.code && this.$route.query.code !== 'undefined') {
        this.registerToWx(this.$route.query.code)
      }
    },
    methods: {
      goPath(url, cli = 0) {
        if (cli != 0) {
          localStorage.setItem('cli', cli)
        }
        this.$router.push(url)
      },
      index() {
        index().then(res => {
          this.news = res.data.data.news
          this.banner = res.data.data.banner
          this.bidding = res.data.data.bidding
          this.bidding_two = res.data.data.bidding_two
          this.enterprise_r = res.data.data.enterprise_r
          this.userNumber = res.data.data.userNumber
          this.priceNumber = res.data.data.priceNumber

          //重置合作企业数据
          let data = res.data.data.enterprise_h;
          let arr_length = data.length;
          let newArr = [];

          for (let i = 0; i < arr_length; i += 12) {
            newArr.push(data.slice(i, i + 12));
          }
          this.enterprise_h = newArr
        })
      },
      getDate() {
        getDate({
          date: this.date
        }).then(res => {
          this.bidding_two_date = res.data.data.dateArray
        })
      },
      cliColor(e, date) {
        this.cli = e
        getDateToData({
          start_date: date
        }).then(res => {
          this.bidding_two = res.data.data
        })
      },
      upCli() {
        this.date += 1
        this.bidding_two_date = []
        this.getDate()
      },
      downCli() {
        if (this.date > 1) {
          this.date -= 1
          this.bidding_two_date = []
          this.getDate()
        }
      },
      registerToWx(code) {
        this.$router.push({
          query: {}
        })

        registerToWx({
          code: code
        }).then(res => {
          if (res.data.code === 1) {
            this.$router.push({
              query: {}
            })
            const token = res.data.data.token
            this.$notify({
              title: '成功',
              message: '登录成功，请稍后',
              type: 'success'
            })
            localStorage.setItem('token', token)

            location.reload()
          } else {
            this.$notify({
              title: '错误',
              message: res.data.msg
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/css/index.scss';
</style>
