import request from '../utils/request.js'

export function getEnterpriseList() {
  return request({
    url: '/api/index/getEnterpriseList',
    method: 'get'
  })
}

export function getHelpCate(data) {
  return request({
    url: '/api/index/getHelpCate',
    method: 'get',
    data
  })
}

export function getHelpCateList(data) {
  return request({
    url: '/api/index/getHelpCateList',
    method: 'get',
    data
  })
}

export function getHelpInfo(data) {
  console.log(data)
  return request({
    url: '/api/index/getHelpInfo',
    method: 'post',
    data
  })
}
